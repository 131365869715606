import React from 'react';
import { Typography } from '@rmwc/typography';

export default function FormHeader({ headline, body }) {
  return (
    <div className="form-header">
      <Typography use="headline5" tag="h2">
        {headline}
      </Typography>
      <Typography use="body1" tag="h3">
        {body}
      </Typography>
    </div>
  );
}
