import React from 'react';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';

export default function FormFooterAction({ text, label, onClick }) {
  return (
    <div className="form-footer__action">
      <Typography use="caption">{text}</Typography>
      <Button onClick={onClick} dense>
        {label}
      </Button>
    </div>
  );
}
