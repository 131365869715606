import React from 'react';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

export default function FormError({ message }) {
  return (
    <div className="form-message form-error">
      <Icon icon={{ icon: 'error_outline', size: 'medium' }} />
      <Typography use="caption" tag="p">
        {message}
      </Typography>
    </div>
  );
}
