import React from 'react';
import FormActions from './FormActions';
import FormBody from './FormBody';
import FormError from './FormError';
import FormField from './FormField';
import FormFooter from './FormFooter';
import FormFooterAction from './FormFooterAction';
import FormHeader from './FormHeader';
import FormLayout from './FormLayout';

import './Form.scss';

export {
  FormActions,
  FormBody,
  FormError,
  FormField,
  FormFooter,
  FormFooterAction,
  FormHeader,
  FormLayout,
};

const INPUT_NODE_NAME = 'INPUT';

function touchInput(node) {
  node.focus();
  node.blur();
}

function findInputs(node, inputNodes = []) {
  const { children, nodeName } = node;

  if (children.length === 0) {
    if (nodeName === INPUT_NODE_NAME) return [node, ...inputNodes];

    return inputNodes;
  }

  return Array.from(children).reduce((inputs, n) => {
    return [...inputs, ...findInputs(n, inputNodes)];
  }, inputNodes);
}

function validateForm(node) {
  const inputs = findInputs(node);

  return inputs.reduce((valid, input) => {
    /* Touch all input events to trigger validation */
    touchInput(input);

    if (!input.validity.valid) return false;

    return valid;
  }, true);
}

export default function Form({
  id = null,
  iRef = null,
  onSubmit,
  customValidations = () => [],
  children,
}) {
  function iOnSubmit(ev) {
    ev.preventDefault();

    const valid = validateForm(ev.target);
    const customValid = customValidations().reduce((cvs, cv) => {
      if (cv) return false;

      return cvs;
    }, true);

    onSubmit(ev, valid && customValid);
  }

  return (
    <form id={id} ref={iRef} onSubmit={iOnSubmit} noValidate>
      {children}
    </form>
  );
}
