import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TextField } from '@rmwc/textfield';

export default function FormField({
  helperText,
  value,
  onChange,
  customValidation,
  ...props
}) {
  const [isInvalid, setIsInvalid] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [showHelperText, setShowHelperText] = useState(helperText || isInvalid);

  function validateFormField(ev) {
    const { value: inputVal, validity, validationMessage: vMsg } = ev.target;
    const { valid } = validity;

    if (customValidation) {
      const customErrorMessage = customValidation(inputVal);

      if (!valid) {
        setIsInvalid(true);
        setValidationMessage(vMsg);
      } else if (customErrorMessage) {
        setIsInvalid(true);
        setValidationMessage(customErrorMessage);
      } else {
        setIsInvalid(false);
        setValidationMessage(null);
      }

      return;
    }

    if (!valid) {
      setIsInvalid(true);
      setValidationMessage(vMsg);
    } else {
      setIsInvalid(false);
      setValidationMessage(null);
    }
  }

  function iOnChange(ev) {
    validateFormField(ev);

    if (onChange) onChange(ev);
  }

  const helperClassName = classNames('mdc-text-field-helper-text', {
    'mdc-text-field-helper-text--show': showHelperText,
    'mdc-text-field-helper-text--validation-msg': isInvalid,
  });

  useEffect(() => {
    setShowHelperText(helperText || isInvalid);
  }, [helperText, isInvalid]);

  return (
    <div className="form-field">
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        invalid={isInvalid}
        onBlur={validateFormField}
        onChange={iOnChange}
      />
      <div className="mdc-text-field-helper-line">
        <div className="mdc-text-field-helper-line">
          <div className={helperClassName}>
            {validationMessage || helperText}
          </div>
        </div>
      </div>
    </div>
  );
}
