import React from 'react';
import AppBar from '../../components/AppBar';
import Layout from '../../components/Layout';

export default function Today() {
  return (
    <Layout>
      <AppBar />
    </Layout>
  );
}
