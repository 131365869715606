import React from 'react';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarTitle,
  TopAppBarActionItem,
} from '@rmwc/top-app-bar';

export default function FutureVision() {
  return (
    <div>
      <TopAppBar>
        <TopAppBarRow>
          <TopAppBarSection>
            <TopAppBarNavigationIcon icon="menu" />
            <TopAppBarTitle>Future Vision</TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <TopAppBarActionItem icon="favorite" />
            <TopAppBarActionItem icon="star" />
            <TopAppBarActionItem icon="mood" />
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
    </div>
  );
}
