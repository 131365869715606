import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { TextField } from '@rmwc/textfield';

import './AppSearchField.scss';

export default function AppSearchField() {
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');

  const className = classNames('app-search-field', {
    'mdc-text-field--has-value': !!searchValue,
  });

  function onSearch(ev) {
    const { value } = ev.target;

    setSearchValue(value);
  }

  function onClearClick() {
    setSearchValue('');
  }

  return (
    <TextField
      className={className}
      inputRef={inputRef}
      icon="search"
      trailingIcon={{
        icon: 'close',
        onClick: onClearClick,
      }}
      label="Search"
      value={searchValue}
      onChange={onSearch}
    />
  );
}
