import React from 'react';
import { Grid, GridCell } from '@rmwc/grid';

export default function FormLayout({ fixedColumnWidth = false, children }) {
  return (
    <Grid className="form-layout" fixedColumnWidth={fixedColumnWidth}>
      <GridCell phone={4} tablet={1} desktop={3} />
      <GridCell phone={4} tablet={6} desktop={6}>
        {children}
      </GridCell>
      <GridCell phone={4} tablet={1} desktop={3} />
    </Grid>
  );
}
