import React from 'react';
import { RMWCProvider } from '@rmwc/provider';
import { SnackbarQueue } from '@rmwc/snackbar';
import Router from './Router';
import Theme from './Theme';
import snackbarQueue from './components/Snackbar/snackbarQueue';

/* --- Material --- */
// theme
import '@material/theme/dist/mdc.theme.css';
import '@rmwc/theme/theme.css';

// global
import '@material/ripple/dist/mdc.ripple.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

// button
import '@material/button/dist/mdc.button.css';

// grid
import '@material/layout-grid/dist/mdc.layout-grid.css';

// icon button
import '@material/icon-button/dist/mdc.icon-button.css';

// list
import '@material/list/dist/mdc.list.css';

// snackbar
import '@material/snackbar/dist/mdc.snackbar.css';

// textfield
import '@material/textfield/dist/mdc.textfield.css';
// textfield deps
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';

// top app bar
import '@material/top-app-bar/dist/mdc.top-app-bar.css';

// typography
import '@material/typography/dist/mdc.typography.css';

/* --- RMWC --- */
// icon
import '@rmwc/icon/icon.css';

function App() {
  return (
    <RMWCProvider>
      <SnackbarQueue messages={snackbarQueue.messages} />
      <Theme>
        <Router />
      </Theme>
    </RMWCProvider>
  );
}

export default App;
