import React from 'react';
import logo from '@woar/core/images/azend-logo.png';

import './authHeader.scss';

export default function AuthHeader() {
  return (
    <div className="auth-header">
      <img src={logo} alt="Azend" />
    </div>
  );
}
