import React from 'react';
import { ThemeProvider } from '@rmwc/theme';

import './MDC.scss';

import colors from '@woar/core/styles/_colors.scss';

const theme = {
  primary: colors.primary,
  secondary: colors.secondary,
};

export default function Theme({ children }) {
  return <ThemeProvider options={theme}>{children}</ThemeProvider>;
}
