import React from 'react';
import { Switch, Route } from 'react-router-dom';
import paths from './paths';
import CreateAccount from '../screens/auth/CreateAccount';
import ResetPassword from '../screens/auth/ResetPassword';
import SignIn from '../screens/auth/SignIn';
import FutureVision from '../screens/FutureVision';
import Today from '../screens/Today';
import NotFound from './NotFound';

function Router() {
  return (
    <Switch>
      <Route path={paths.createAccount} exact>
        <CreateAccount />
      </Route>
      <Route path={paths.resetPassword} exact>
        <ResetPassword />
      </Route>
      <Route path={paths.signIn} exact>
        <SignIn />
      </Route>
      <Route path={paths.futureVision} exact>
        <FutureVision />
      </Route>
      <Route path={paths.today} exact>
        <Today />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default Router;
