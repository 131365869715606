export default {
  home: '/today',
  signIn: '/auth/sign-in',
  createAccount: '/auth/create-account',
  resetPassword: '/auth/reset-password',
  account: '/user/account',
  today: '/today',
  futureVision: '/future-vision',
  goals: '/goals',
  principles: '/principles',
  habits: '/habits',
  components: '/screens',
};
