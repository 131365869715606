import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { useResetPasswordStore } from '@woar/core/stores/AuthFormStore';
import Form, {
  FormActions,
  FormBody,
  FormError,
  FormField,
  FormFooter,
  FormFooterAction,
  FormHeader,
  FormLayout,
} from '../../components/Form';
import snackbarQueue from '../../components/Snackbar/snackbarQueue';
import paths from '../../Router/paths';
import AuthHeader from './AuthHeader';

export default function ResetPassword() {
  const history = useHistory();
  const formRef = useRef(null);
  const {
    email,
    setEmail,
    errorMessage,
    sendPasswordResetEmail,
  } = useResetPasswordStore();

  const formId = 'ResetPassword';
  const toSignIn = () => history.push(paths.signIn);

  async function submitResetPasswordForm(ev, ok) {
    if (ok) {
      const [, err] = await sendPasswordResetEmail();

      if (!err) {
        snackbarQueue.notify({
          body: `We sent an email to ${email} with instructions on how to reset your password.`,
          timeout: 10000,
        });

        history.push(paths.signIn);
      }
    }
  }

  return (
    <FormLayout fixedColumnWidth={true}>
      <AuthHeader />
      <FormHeader
        headline="Reset your password"
        body="You will receive a verification code from us to reset your password."
      />
      {errorMessage && <FormError message={errorMessage} />}
      <FormBody>
        <Form id={formId} iRef={formRef} onSubmit={submitResetPasswordForm}>
          <FormField
            type="email"
            label="Email"
            value={email}
            onChange={ev => {
              setEmail(ev.target.value);
            }}
            outlined
            required
          />
        </Form>
      </FormBody>
      <FormActions>
        <Button form={formId} type="submit" raised>
          Submit
        </Button>
      </FormActions>
      <FormFooter>
        <FormFooterAction label="Go to Sign In" onClick={toSignIn} />
      </FormFooter>
    </FormLayout>
  );
}
