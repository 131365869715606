import React from 'react';
import {
  TopAppBar,
  TopAppBarActionItem,
  TopAppBarNavigationIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@rmwc/top-app-bar';
import AppSearchField from '../AppSearchField';

export default function Index() {
  return (
    <TopAppBar>
      <TopAppBarRow>
        <TopAppBarSection alignStart>
          <TopAppBarNavigationIcon icon="menu" />
          <TopAppBarTitle>Future Vision</TopAppBarTitle>
        </TopAppBarSection>
        <TopAppBarSection>
          <AppSearchField />
        </TopAppBarSection>
        <TopAppBarSection alignEnd>
          <TopAppBarActionItem icon="account_circle" />
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  );
}
