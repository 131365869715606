import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { useCreateAccountStore } from '@woar/core/stores/AuthFormStore';
import Form, {
  FormActions,
  FormBody,
  FormError,
  FormField,
  FormFooter,
  FormFooterAction,
  FormHeader,
  FormLayout,
} from '../../components/Form';
import paths from '../../Router/paths';
import AuthHeader from './AuthHeader';
import snackbarQueue from '../../components/Snackbar/snackbarQueue';

export default function CreateAccount() {
  const history = useHistory();
  const formRef = useRef(null);
  const {
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    errorMessage,
    createUserWithEmailAndPassword,
  } = useCreateAccountStore();

  const formId = 'CreateAccount';
  const toSignIn = () => history.push(paths.signIn);

  function confirmPasswordValidation(value) {
    if (password !== value) {
      return 'Value must match password.';
    }

    return null;
  }

  async function submitCreateAccountForm(ev, ok) {
    if (ok) {
      const [, err] = await createUserWithEmailAndPassword();

      if (!err) {
        snackbarQueue.notify({
          body: `We sent an email to ${email} with instructions on how to verify your account.`,
          timeout: 10000,
        });

        history.push(paths.home);
      }
    }
  }

  return (
    <FormLayout fixedColumnWidth={true}>
      <AuthHeader />
      <FormHeader headline="Create a new account" />
      {errorMessage && <FormError message={errorMessage} />}
      <FormBody>
        <Form
          id={formId}
          iRef={formRef}
          onSubmit={submitCreateAccountForm}
          customValidations={() => [confirmPasswordValidation(confirmPassword)]}
        >
          <FormField
            type="email"
            label="Email"
            value={email}
            onChange={ev => {
              setEmail(ev.target.value);
            }}
            outlined
            required
          />
          <FormField
            type="password"
            label="Password"
            value={password}
            onChange={ev => {
              setPassword(ev.target.value);
            }}
            outlined
            required
          />
          <FormField
            type="password"
            label="Confirm Password"
            value={confirmPassword}
            onChange={ev => {
              const { value: newCp } = ev.target;

              setConfirmPassword(newCp);
            }}
            customValidation={confirmPasswordValidation}
            outlined
            required
          />
        </Form>
      </FormBody>
      <FormActions>
        <Button form={formId} type="submit" raised>
          Create Account
        </Button>
      </FormActions>
      <FormFooter>
        <FormFooterAction
          text="Do you already have an account?"
          label="Sign In"
          onClick={toSignIn}
        />
      </FormFooter>
    </FormLayout>
  );
}
