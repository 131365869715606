/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from 'firebase/app';
import { BrowserRouter } from 'react-router-dom';
import firebaseConf from '@woar/core/config/firebase.staging';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

firebase.initializeApp(firebaseConf);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
