import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from 'firebase';
import { Button } from '@rmwc/button';
import { useSignInFormStore } from '@woar/core/stores/AuthFormStore';
import Form, {
  FormActions,
  FormBody,
  FormError,
  FormField,
  FormFooter,
  FormFooterAction,
  FormHeader,
  FormLayout,
} from '../../components/Form';
import snackbarQueue from '../../components/Snackbar/snackbarQueue';
import paths from '../../Router/paths';
import AuthHeader from './AuthHeader';

export default function SignIn() {
  const history = useHistory();
  const formRef = useRef(null);
  const {
    email,
    setEmail,
    password,
    setPassword,
    errorMessage,
    signInWithEmailAndPassword,
    isEmailVerified,
  } = useSignInFormStore();

  const formId = 'SignUp';
  const toResetPassword = () => history.push(paths.resetPassword);
  const toCreateAccount = () => history.push(paths.createAccount);

  async function submitSignInForm(ev, ok) {
    if (ok) {
      const [, err] = await signInWithEmailAndPassword();

      if (!err) {
        if (!isEmailVerified()) {
          snackbarQueue.notify({
            body: 'You have not verified your email address.',
            stacked: true,
            timeout: 10000,
            dismissIcon: false,
            dismissesOnAction: true,
            actions: [
              {
                label: 'Send Verification',
                onClick: () => auth().currentUser.sendEmailVerification(),
              },
            ],
          });
        }

        history.push(paths.home);
      }
    }
  }

  return (
    <>
      <FormLayout fixedColumnWidth={true}>
        <AuthHeader />
        <FormHeader headline="Sign in to your account" />
        {errorMessage && <FormError message={errorMessage} />}
        <FormBody>
          <Form id={formId} iRef={formRef} onSubmit={submitSignInForm}>
            <FormField
              type="email"
              label="Email"
              value={email}
              onChange={ev => {
                setEmail(ev.target.value);
              }}
              outlined
              required
            />
            <FormField
              type="password"
              label="Password"
              value={password}
              onChange={ev => {
                setPassword(ev.target.value);
              }}
              outlined
              required
            />
          </Form>
        </FormBody>
        <FormActions>
          <Button form={formId} type="submit" raised>
            Sign In
          </Button>
        </FormActions>
        <FormFooter>
          <FormFooterAction
            text="If you are new, we would love to have you."
            label="Create Account"
            onClick={toCreateAccount}
          />
          <FormFooterAction
            text="Forgot your password?"
            label="Reset Password"
            onClick={toResetPassword}
          />
        </FormFooter>
      </FormLayout>
    </>
  );
}
